import React from 'react';
import Post from './Post'
import { Route, Link, BrowserRouter as Router, Switch } from 'react-router-dom'
import Blok from './Blok';

 

function App() {
  return (
      <React.Fragment>

        <Router>

          <Switch>
            <Route exact path="/" component={Blok} />
            <Route path="/p/:id" component={Post} />
          </Switch>

        </Router>

      </React.Fragment>
  )
}



export default App;
