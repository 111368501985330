import React from 'react'
import axios from 'axios'
import Menu from './menu'
import Page from 'react-page-loading'
import Disqus from 'disqus-react';
class Post extends React.Component{

    constructor(props) {
        super(props)
        this.state = {
            title : [],
            body : [],
            name : [],
            kategori : [],
            items: [],
            isLoading : true
        }
    }

    componentDidMount() {
        const { match: { params } } = this.props;
        axios.get(`http://localhost:8000/api/posts/`+ params.id)
          .then(res => {
            const items = res.data;
            this.setState({ items });

            const title = items['data'].title;
            this.setState({ title })

            const body = items['data'].body;
            this.setState({ body })

            const name = items['data'].name;
            this.setState({ name })

            const kategori = items['data'].kategori;
            this.setState({ kategori })

            this.setState({ isLoading : false })

          })
      }




    render() {
        const { match: { params } } = this.props;
        var image = "http://iekang.rf.gd/xxx.jpg";
        const disqusShortname = 'http-localhost-3000-jkzyuutua4';
        const disqusConfig = {
            url: this.props.pageUrl,
            identifier: params.id,
            title: this.state.title,
        };

        const {items , isLoading} = this.state
        if(isLoading){
            return (
                <div>
                  <Page loader={"bar"} color={"#000"} size={5}>
                  </Page>
                </div>
            )
        }else{
        return(
            <React.Fragment>
                    <Menu />
        <main id="main-container">
        <div id="fb-root"></div>
        
        <div className="bg-image" style ={ { backgroundImage: "url("+image+")" } }>
            <div className="bg-primary-dark-op">
                <section className="content content-full content-boxed">
                    <div className="push-100-t push-50 text-center">
                        <h1 className="h2 text-white push-10 " data-toggle="appear" data-className="animated fadeInDown">Namkaz Subs</h1>
                        <h2 className="h4 text-white-op " data-toggle="appear" data-className="animated fadeInDown">{ this.state.title }</h2>
                    </div>
                </section>
            </div>
        </div>

        <div className="bg-white">
            <section className="content content-mini content-mini-full content-boxed overflow-hidden">
                <ol className="breadcrumb">
                    <li><a className="text-primary-dark" href="/">Home</a></li>
        <           li><a>{ this.state.title }</a></li>
                </ol>
            </section>
        </div>

        <section className="content content-boxed overflow-hidden">
            <div className="row">
                <div className="col-md-8">
                    <div className="block block-rounded">
                        <div className="block-content">
                            
                            <p className="font-w600" dangerouslySetInnerHTML={{__html: this.state.body }}></p>
                           
                        
                        </div>
                    </div>
                    <Disqus.DiscussionEmbed
                        shortname={disqusShortname}
                        config={disqusConfig}
                        />
                </div>
                <div className="col-md-4">
                    <div className="block block-rounded">
                        
                    </div>
                    <div className="block block-rounded">
                        <div className="block-header bg-primary-gray-op text-center">
                            <h3 className="block-title">Tentang Halaman Ini</h3>
                        </div>
                        <div className="block-content">
                            <table className="table table-borderless table-condensed">
                                <tbody>
                                    <tr>
                                        <td>Jumlah Pengunjung : </td>
                                    </tr>
                                    <tr>
                                        <td>kategori : <a className="label label-primary">{ this.state.kategori }</a></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div className="block-header bg-primary-gray-op text-center">
                            <h3 className="block-title">Notifikasi</h3>
                        </div>
                        <div className="block-content">
                            <table className="table table-borderless table-condensed">
                                <tbody>
                                    <tr>
                                        <td><span className="block-title" >
                                        Mohon Klik Iklan 1 kali / 2 Kali .. <br></br>Untuk Memajukan Blog Ini... <br></br> Terima Kasih
                                        </span> </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div className="block-header bg-primary-gray-op text-center">
                            <h3 className="block-title">Iklan</h3>
                        </div>
                        <div className="block-content">
                            <table className="table table-borderless table-condensed">
                                <tbody>
                                    <tr>
                                        <td>
                                            
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        </main>

</React.Fragment>
        )
        }
    }
}

export default Post