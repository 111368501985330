import React from 'react'
import { Route, Link, BrowserRouter as Router, Switch } from 'react-router-dom'
import Menu from './menu'
import Pagination from "react-js-pagination";

class Blok extends React.Component {

    constructor() {
        super()
    
        // data provinsi disimpan di state.provinces
        this.state = {
          provinces: [],
          activePage : 1,
          itemsCountPerPage : 1,
          totalItemsCount : 1,
          pageRangeDisplayed : 3
        };
        this.handlePageChange = this.handlePageChange.bind(this);
      }
    
      componentDidMount() {
        // ajax call
        fetch('http://localhost:8000/api/page')
        .then(response => response.json())
        .then((json) => {
          this.setState({
            provinces: json.data,
            activePage : json.meta.pagination.current_page,
            itemsCountPerPage : json.meta.pagination.per_page,
            totalItemsCount : json.meta.pagination.total,
          })
        })
      }
      handlePageChange(pageNumber) {
        console.log(`active page is ${pageNumber}`);
        // this.setState({activePage: pageNumber});
        fetch('http://localhost:8000/api/page?page='+pageNumber)
        .then(response => response.json())
        .then((json) => {
          this.setState({
            provinces: json.data,
            activePage : json.meta.pagination.current_page,
            itemsCountPerPage : json.meta.pagination.per_page,
            totalItemsCount : json.meta.pagination.total,
          })
        })
      }
      renderProvinces(item, index) {
          
        return (
            <div className="block">
            <div className="block-content">
                <div className="row items-push"  style={{ position: 'relative', height: '100%' }}>
                    <div className="col-md-4"> <img
                                className="img-responsive" key={index} src={item.thumbal} alt="" />
                    </div>
                    <div className="col-md-8">
                        <div className="font-s12 push-10"><em className="pull-right">{item.waktu}</em> <a
                                href="base_pages_profile.html" key={index}> {item.name} </a> 
                        </div>
                        <h4 className="text-uppercase push-10"><Link to={`/p/${item.id}`} className="text-primary-dark" key={index} >{item.title}</Link></h4>
                        <p className="push-20" key={index}>
                            <b>Posted By</b> Fu 
                            <br></br>
                            <b>Release On </b> {item.waktu}
                            <br></br>
                            <b>Kategori </b> {item.kategori}
                        </p>
                        
                    </div>
                    <div className="btn-group btn-group-sm" style={{ position: 'absolute', bottom: '0px', right: '30px' }}>
                            <a className="btn btn-default"> Share</a> 
                            <Link className="btn btn-default" to={`/p/${item.id}`}>Continue
                                Reading..</Link></div>
                </div>
            </div>
        </div>
        )
        
      }
    
      render() {
        var image = "https://i.imgur.com/4TEy9YL.jpg";
        
        return (
        <React.Fragment>
                    <Menu />
        <main id="main-container">
        <div>
        <div className="bg-image" style ={ { backgroundImage: "url("+image+")",backgroundPositionY: "-50px", backgroundSize: "cover"} }>
            <div className="bg-primary-dark-op">
                <section className="content content-full content-boxed">
                    <div className="push-100-t push-50 text-center">
                        <h1 className="h2 text-white push-10 " data-toggle="appear" data-className="animated fadeInDown">Namkaz Subs</h1>
                        <h2 className="h4 text-white-op " data-toggle="appear" data-className="animated fadeInDown">Selamat Datang Namkaz Subs Version &bull; 2 </h2>
                    </div>
                </section>
            </div>
        </div>
             <section className="content content-boxed">
                    <div className="push-50-t push-50">
                        <div className="row">
                                <div className="col-md-8">
                                { this.state.provinces.map(this.renderProvinces) }  

                                <nav>
                                    <Pagination
                                    activePage={this.state.activePage}
                                    itemsCountPerPage={this.state.itemsCountPerPage}
                                    totalItemsCount={this.state.totalItemsCount}
                                    pageRangeDisplayed={this.state.pageRangeDisplayed}
                                    onChange={this.handlePageChange}
                                    innerClass= 'pagination'
                                    activeClass='active'
                                    />
                                </nav>
                                </div>
                                
                                <div className="col-md-4">
                                <div className="block">
                                    <div className="block-header bg-gray-lighter">
                                        <h3 className="block-title">Search</h3>
                                    </div>
                                    <div className="block-content block-content-full">
                                        <form action="frontend_search.html" method="post">
                                            <div className="input-group input-group-lg">
                                                <input className="form-control" type="text" placeholder="Type and hit enter.." />
                                                <div className="input-group-btn">
                                                    <button className="btn btn-default"><i className="fa fa-search"></i></button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                                <a className="block block-link-hover3" href="https://www.facebook.com/namkazsubs">
                                    <div className="block-header bg-gray-lighter">
                                        <h3 className="block-title text-center">Fanspage</h3>
                                    </div>
                                
                                    {/* <FacebookProvider appId >
                                        <Page href="https://www.facebook.com/namkazsubs" tabs="timeline"  />
                                    </FacebookProvider>     */}
                                    <div className="block-content"><div className="fb-page" data-href="https://www.facebook.com/namkazsubs/" data-tabs="timeline" data-width="" data-height="50" data-small-header="false" data-adapt-container-width="true" data-hide-cover="false" data-show-facepile="true"><blockquote cite="https://www.facebook.com/namkazsubs/" className="fb-xfbml-parse-ignore"><a href="https://www.facebook.com/namkazsubs/">Namkaz Subs</a></blockquote></div>
                                    </div>
                                   
                                </a>

                                <div className="block">
                                    <div className="block-header bg-gray-lighter">
                                        <ul className="block-options">
                                            <li>
                                                <button type="button" data-toggle="block-option" data-action="refresh_toggle" data-action-mode="demo"><i className="si si-refresh"></i></button>
                                            </li>
                                        </ul>
                                        <h3 className="block-title">Iklan</h3>
                                    </div>
                                    <div className="block-content">
                                        <ul className="list list-simple">
                                            <li>
                                                <div className="push-5 clearfix">
                                                    <span className="font-s13 text-muted push-10-l pull-right">25 min ago</span>
                                                    <a className="font-w600" href="base_pages_profile.html">Donald Barnes</a> on <a href="frontend_blog_story.html">Exploring the Alps</a>
                                                </div>
                                                <div className="font-s13">Awesome trip! Looking forward going there, I'm sure it will be a great experience!</div>
                                            </li>
                                           
                                        </ul>
                                        <div className="text-center push">
                                            <small><a href="javascript:void(0)">Read More..</a></small>
                                        </div>
                                    </div>
                                </div>
                                <div className="block">
                                    <div className="block-header bg-gray-lighter">
                                        <h3 className="block-title">Social</h3>
                                    </div>
                                    <div className="block-content block-content-full">
                                        <div className="btn-group">
                                            <a className="btn btn-default" href="javascript:void(0)" data-toggle="tooltip" title="Follow us on Twitter"><i className="fa fa-fw fa-twitter"></i></a>
                                            <a className="btn btn-default" href="https://www.facebook.com/namkazsubs" data-toggle="tooltip" title="Like our Facebook page"><i className="fa fa-fw fa-facebook"></i></a>
                                            <a className="btn btn-default" href="javascript:void(0)" data-toggle="tooltip" title="Follow us on Google Plus"><i className="fa fa-fw fa-google-plus"></i></a>
                                            <a className="btn btn-default" href="javascript:void(0)" data-toggle="tooltip" title="Follow us on Dribbble"><i className="fa fa-fw fa-dribbble"></i></a>
                                            <a className="btn btn-default" href="javascript:void(0)" data-toggle="tooltip" title="Subscribe on Youtube"><i className="fa fa-fw fa-youtube"></i></a>
                                        </div>
                                    </div>
                                </div>
                            </div>   
                        </div>
                </div>
            </section>
        </div>
        
        </main>
        
        </React.Fragment>
       
        );
      }
    }

export default Blok