import React from 'react'
import { Route, Link, BrowserRouter as Router, Switch } from 'react-router-dom'

class Menu extends React.Component {

    render() {
        return (
            <header id="header-navbar" className="content-mini content-mini-full">
            <div className="content-boxed">
              
                <ul className="nav-header pull-right">
                    <li>
                     
                        <div className="btn-group">
                            <button className="btn btn-link text-white dropdown-toggle" data-toggle="dropdown" type="button">
                                <i className="si si-drop"></i>
                            </button>
                        </div>
                    </li>
                    <li className="hidden-md hidden-lg">
                       
                        <button className="btn btn-link text-white pull-right" data-toggle="className-toggle" data-target=".js-nav-main-header" data-className="nav-main-header-o" type="button">
                            <i className="fa fa-navicon"></i>
                        </button>
                    </li>
                </ul>
                <ul className="js-nav-main-header nav-main-header pull-right">
                    <li className="text-right hidden-md hidden-lg">
                       
                        <button className="btn btn-link text-white" data-toggle="className-toggle" data-target=".js-nav-main-header" data-className="nav-main-header-o" type="button">
                            <i className="fa fa-times"></i>
                        </button>
                    </li>
                    <li>
                        <a className="active" href="/">Home</a>
                    </li>
                    {/* <li>
                        <a className="nav-submenu" href="javascript:void(0)">Pages</a>
                        <ul>
                            <li>
                                <a href="frontend_team.html">Team</a>
                            </li>
                            <li>
                                <a href="frontend_support.html">Support</a>
                            </li>
                            <li>
                                <a href="frontend_search.html">Search</a>
                            </li>
                            <li>
                                <a href="frontend_about.html">About</a>
                            </li>
                            <li>
                                <a href="frontend_login.html">Login</a>
                            </li>
                            <li>
                                <a href="frontend_signup.html">Sign Up</a>
                            </li>
                        </ul>
                    </li> */}
                    <li>
                        <Link to="halaman/admin">Admin</Link>
                    </li>
                    <li>
                    <Link to="halaman/charakter">Character List</Link>
                    </li>
                    <li>
                        <Link to="halaman/request">Request Character</Link>
                    </li>
                    <li>
                        <Link to="halaman/report">Report Pages</Link>
                    </li>
                    <li>
                        <Link to="about">About</Link>
                    </li>
                </ul>
            </div>
        </header>
        );
    }

}

export default Menu